(function ($, Drupal, aria) {
  Drupal.behaviors.gnavSignupV1 = {
    // # of seconds before the overlay appears
    defaultOpenTimeout: 3000,
    isOverlayHovered: false,
    attach: function (context) {
      var self = this;
      var $modules = $('.js-gnav-signup--v1', context);

      function _closeOverlay($closeDialog, closeTimeout) {
        var timer = false;

        // Close the overlay after some timeout
        if (closeTimeout) {
          timer = setTimeout(function () {
            if (!self.isOverlayHovered) {
              $closeDialog.trigger('close_overlay');
            }
          }, closeTimeout * 1000);
        }

        return timer;
      }
      function _setCookie(cookieName, expires) {
        var attributes = {
          path: '/'
        };

        if (expires) {
          attributes.expires = expires;
        }
        $.cookie(cookieName, 1, attributes);
      }

      $modules.each(function () {
        var $module = $(this);
        var $overlayTrigger = $('.js-gnav-signup-trigger', $module);
        var $contentOverlay = $('.js-gnav-signup-overlay', $module);
        var $dialog = $('[role="dialog"]', $contentOverlay);
        var $closeDialog = $('.js-gnav-signup-overlay-close', $dialog);
        var closeTimeout =
          $contentOverlay.length && $contentOverlay.data('close-timeout')
            ? $contentOverlay.data('close-timeout')
            : 0;
        var displayOnload =
          $contentOverlay.length && $contentOverlay.data('display-onload')
            ? $contentOverlay.data('display-onload')
            : false;
        var keepOpen =
          $contentOverlay.length && $contentOverlay.data('keep-open')
            ? $contentOverlay.data('keep-open')
            : false;
        var hoverTriggerEvents = $overlayTrigger.data('trigger-on-hover')
          ? ' mouseover.overlay'
          : '';
        var cookieName = $dialog.attr('id') ? $dialog.attr('id') : 'gnav_email_signup_overlay';
        var hasCookie = $.cookie(cookieName);
        var closeTimer;

        $overlayTrigger
          .off('click.overlay' + hoverTriggerEvents)
          .on('click.overlay' + hoverTriggerEvents, function (e) {
            clearTimeout(closeTimer);
            if ($contentOverlay.length) {
              e.preventDefault();
              $(document).trigger('searchClose');
              $(document).trigger('navClose');
              $(this).attr('aria-expanded', 'true');
              self.isOverlayHovered = true;
              $contentOverlay.trigger('launch.overlay');
            }
          })
          .on('mouseleave.trigger', function () {
            if (hoverTriggerEvents && !keepOpen) {
              _closeOverlay($closeDialog, 0.5);
              self.isOverlayHovered = false;
            }
          });

        $module.on('clickoutside.trigger', function (e) {
          if ($('#onetrust-consent-sdk').length) {
            return;
          }
          var $whatGotClicked = $(e.target);

          if (
            !$whatGotClicked.hasClass('cookie_legal-close_box') &&
            !$whatGotClicked.hasClass('cookie_legal-language') &&
            !($whatGotClicked.attr('id') === 'cookie_popover_container')
          ) {
            _closeOverlay($closeDialog, 0.25);
            self.isOverlayHovered = false;
          }
        });

        function handleClose() {
          var $this = $(this);
          var $btnTrigger = $this
            .parents('.js-gnav-signup-overlay')
            .siblings('.js-gnav-signup-trigger');
          var closeButton = $this.get(0);
          var topDialog = aria.getCurrentDialog();

          if (topDialog && topDialog.dialogNode && topDialog.dialogNode.contains(closeButton)) {
            topDialog.close();
            _setCookie(cookieName, $this.data('expires'));
            $(document).trigger('gnavSignupClosed');
            if ($btnTrigger.length) {
              $btnTrigger.attr('aria-expanded', 'false');
            }
          }
        }

        $closeDialog.on('close_overlay', handleClose);
        $closeDialog.off('click.close').on('click.close', handleClose);

        $contentOverlay
          .off('launch.overlay')
          .on('launch.overlay', function () {
            var $btnTrigger = $(this).siblings('.js-gnav-signup-trigger');

            if ($btnTrigger.length) {
              new aria.Dialog($dialog.attr('id'), $btnTrigger.get(0));
            } else {
              new aria.Dialog($dialog.attr('id'), $module.get(0));
            }
          })
          .on('mouseenter', function () {
            if (keepOpen || displayOnload) {
              return;
            }
            clearTimeout(closeTimer);
            self.isOverlayHovered = true;
          })
          .on('mouseleave', function (e) {
            // Avoid mouseout on select input suggestion & on hover input field(Firefox).
            if (
              keepOpen ||
              displayOnload ||
              e.relatedTarget === null ||
              e.relatedTarget.nodeName.toLowerCase() === 'input'
            ) {
              return;
            }
            // Close the overlay after a time to prevent the overlay from closing too quickly when submitting the form.
            _closeOverlay($closeDialog, 0.5);
            self.isOverlayHovered = false;
          });

        $(document).on('CloseGnavSignup', function () {
          _closeOverlay($closeDialog, 0.1);
        });

        $(document).on('user.loaded', function () {
          if (!hasCookie && displayOnload) {
            // Open the Overlay on initial site load after a default timeout
            setTimeout(function () {
              $contentOverlay.trigger('launch.overlay');
              _setCookie(cookieName, $closeDialog.data('expires'));
              // Close the overlay after some timeout
              closeTimer = _closeOverlay($closeDialog, closeTimeout);
            }, self.defaultOpenTimeout);
          }
        });
      });
    }
  };
})(jQuery, Drupal, window.aria);
